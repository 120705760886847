import { BasicsClass } from '@/common/BasicsClass';

export const bigScreenGovernmentCamelNewVideoList = (form: any, page: any, cb: Function) => { //骆驼大屏视频
    new BasicsClass().BasicGet('/animal/web/bigScreenGovernmentCamelNew/videoList', { ...form, ...page }, false, false, cb, () => { }, false)
}

export const getTakeStock = (form: any,  cb: Function) => { //获取盘点数量
    new BasicsClass().BasicGet('/animal/web/bigScreenGovernmentCamelNew/getTakeStock', { ...form }, false, false, cb, () => { }, false)
}

export const getStatInColumnCount = (form: any,  cb: Function) => { //获取盘点统计图
    new BasicsClass().BasicGet('/animal/web/bigScreenGovernmentCamelNew/getStatInColumnCount', { ...form }, false, false, cb, () => { }, false)
}

export const getBigScreenGovernmentVideoGroupCodeV333 = (cb: Function) => { //政府大屏V333版本  视频 组织机构
    new BasicsClass().BasicGet('/animal/web/bigScreenGovernmentNew/video/governmentGroupCodeV333', { animalTypeId: 2 }, false, false, cb)
}

export const getBigScreenGovernmentVideoListV333 = (form: any, page: any, cb: Function) => {//政府大屏V333版本  视频
    new BasicsClass().BasicGet('/animal/web/bigScreenGovernmentNew2/videoListV333', { ...form, ...page, animalTypeId: 2, streamType: 1 }, false, false, cb, () => { }, false)
}

export const getBigScreenGovernmentVideoysGroupTree = (cb: Function) => { //政府大屏V333版本  视频 组织机构
    new BasicsClass().BasicGet('/animal/web/newInsuranceLargeScreen/ysGroupTree', { animalTypeId: 2}, false, false, cb)
}

export const getBigScreenGovernmentysVideoList = (form: any, page: any, cb: Function) => {//政府大屏V333版本  视频
    new BasicsClass().BasicGet('/animal/web/newInsuranceLargeScreen/ysVideoList', { ...form, ...page, animalTypeId: 2, streamType: 1 }, false, false, cb, () => { }, false)
}