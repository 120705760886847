import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/Views/comm/util';
import { HandleTable } from '@/common/Views/comm/handleTable';

/*
export interface SchoolAndStudent {
    schoolCount: number;
    studentInfo: StudentInfo;
}

interface StudentInfo {
    studentCount: number;
    innerCount: number;
    outerCount: number;
    femaleCount: number;
    maleCount: number;
}

export interface Temperature {
    total: number;
    week: Week;
    school: Week;
}

export interface Week {
    tile: string[];
    list: number[];
    title: string[];
}

export interface Out {
    count: number;
    active: Week;
}
*/

export interface FrameData {
    data: Frame[];
    defaultPrice: DefaultPrice;
}

interface DefaultPrice {
    areaId: string;
    areaName: string;
    createTime: string;
    id: string;
    price: string;
    updateTime: string;
}

export interface Frame extends SelectPopInt {
    address: string;
    detailAddress: string;
    deviceId: string;
    groupCode: string;
    groupId: string;
    groupName: string;
    latitude: string;
    level: number;
    longitude: string;
    mobile: string;
    name: string;
    parentId: string;
    person: string;
    price: string;
    simpleAddress: string;
    sysFarmId: string;
}

export interface EChartsFrame extends Frame {
    coord: string[];
    symbol: string;
    symbolSize: number;
}

export interface Temperature {
    abnormalCount: number;
    percent: string;
    sysFarmId: string;
    sysFarmName: string;
    toatal: number;
}

export interface SlaughterStatic {
    datas: Datas[];
    days: string[];
    sum: number[];
    type: string;
}

interface Datas {
    count: number[];
    typeConfId: string;
    typeConfName: string;

}


export interface SmartInventory {
    data: SmartInventoryData[];
    sum: number;
    type: string;
}

export interface SmartInventoryData {
    count: number;
    day: number;
    groupCode: string;
    sysFarmId: string;
    sysFarmName: string;
    typeConfId: string;
    typeConfName: string;
    typeId: string;
}

export interface SelectPopInt {
    name: string;
    id: string;
    sysFarmId?: string;
}

export interface InventoryStatistics {
    listx: string[];
    listy: number[];

}

export interface HouseList {
    createTime: string;
    createUserId: string;
    deleteFlag: boolean;
    farmId: string;
    id: string;
    parentId: string;
    permissionFarmIdList: string[];
    permissionFarmIds: string;
    permissionGroupCode: string;
    permissionUserId: string;
    placeName: string;
    updateTime: string;
    updateUserId: string;
}

export interface VideoList {
    createTime: string;
    createUser: string;
    createUserName: string;
    deleteFlag: boolean;
    farmId: string;
    farmName: string;
    groupCode: string;
    groupName: string;
    id: string;
    placeId: string;
    placeName: string;
    remark: string;
    rtmpUrl: string;
    status: boolean;
    updateTime: string;
    updateUser: string;
    updateUserName: string;
    m3u8Url: string;
}

export interface Price {
    days: string[];
    prices: string[];
}
export interface PriceToday {
    pigPrice: string;
    valuation: string;
}

export class BigData extends BasicsClass {
    /*public schoolAndStudent(id: string|null, cb: Function): void {
            this.getTableList({id}, {}, '/edu/data/home/schoolAndStudent', cb);
    }

    public temperature(id: string|null, cb: Function): void {
        this.getTableList({id}, {}, '/edu/biz/home/temperature', cb);
    }

    public absent(id: string|null, cb: Function): void {
        this.getTableList({id}, {}, '/edu/biz/home/absent', cb);
    }

    public warn(id: string|null, cb: Function): void {
        this.getTableList({id}, {}, '/edu/biz/home/warn', cb);
    }

    public out(id: string|null, cb: Function): void {
        this.getTableList({id}, {}, '/edu/biz/home/out', cb);
    }

    public getWarningNotification(id: string|null, cb: Function): void {
        const startTime: string = new Util().dateTimeUtil(new Date((new Date().getTime() - 1000*60)).toString());
        const endTime: string = new Util().dateTimeUtil(new Date().toString());

        this.getTableList({
            schoolId: id,
            startTime,
            endTime
        }, {}, '/edu/biz/eduWarning/list', cb);
    }*/

    // public static sysFarmId: string = '';
    // public groupCode: string = localStorage.getItem('groupCode')!;

    private getLocalStorage(name: string) {
        return localStorage.getItem(name);
    }

    public getFrame(cb: Function) {
        this.BasicGet('/animal/web/bigScreen/getSysFarm', {
            groupCode: this.getLocalStorage('groupCode'),
        }, false, true, cb);
    }

    public getFrame1(data: any, cb: Function) {
        this.BasicGet('/animal/web/bigScreen/getMapFarm', data, false, true, cb);
    }


    public getTemperature(farmId: string, cb: Function) {
        this.BasicGet('/animal/web/bigScreen/abnormalTemperature', {
            groupCode: this.getLocalStorage('groupCode'),
            farmId
        }, false, true, cb);
    }


    //种类列表
    /*public getTypes(cb: Function) {
        this.BasicGet('/animal/web/bigScreen/getTypes?groupCode=000000', {
            groupCode: localStorage.getItem('groupCode')
        }, false, true, cb);
    }*/

    //获取当前登陆者的组织机构
    public getForGroupCodeForChange(cb: Function) {
        this.BasicGet('/animal/web/provinceCityDistrict/getForGroupCodeForChange', {
        }, false, true, cb);
    }

    //出栏情况
    public getsevenmarketing(cb: Function, i: any) {
        this.BasicGet('/animal/web/bigScreenForCountry/sevenmarketing', {
            groupCode: this.getLocalStorage('groupCode'),
            animalTypeId: i
        }, false, true, cb);
    }


    //存栏情况
    public getlivestock(cb: Function, i: any) {
        this.BasicGet('/animal/web/bigScreenForCountry/livestock', {
            groupCode: this.getLocalStorage('groupCode'),
            animalTypeId: i
        }, false, true, cb);
    }


    //统计区域下养殖场所类型数量和每日屠宰数
    public getcountForPlaceAndKill(cb: Function) {
        this.BasicGet('/animal/web/bigScreenForCountry/countForPlaceAndKill', {
            groupCode: this.getLocalStorage('groupCode'),
        }, false, true, cb);
    }

    //调出情况//调入情况
    public getmobilizeByDayForInAndOut(cb: Function, i: any) {
        this.BasicGet('/animal/web/bigScreenForCountry/mobilizeByDayForInAndOut', {
            groupCode: this.getLocalStorage('groupCode'),
            animalTypeId: i
        }, false, true, cb);
    }

    //调入情况
    public getmobilizeByDayForIn(cb: Function, i: any) {
        this.BasicGet('/animal/web/bigScreenForCountry/mobilizeByDayForIn', {
            groupCode: this.getLocalStorage('groupCode'),
            animalTypeId: i
        }, false, true, cb);
    }

    //出栏情况
    public getmarketing(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForCompany/marketing', {
            // animalTypeId: form.animalTypeId,
            // today: form.day,
            // currentWeek:form.week,
            // currentMonth:form.month,
            // pName:form.pName
            ...form
        }, false, true, cb);
    }

    //死亡情况
    public getdeathSituation(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForCompany/deathSituation', {
            // animalTypeId: form.animalTypeId,
            // today: form.day,
            // currentWeek:form.week,
            // currentMonth:form.month,
            // pName:form.pName
            ...form
        }, false, true, cb);
    }


    //实时盘点
    public getlivestock2(cb: Function, from: any) {
        this.BasicGet('/animal/web/bigScreen/livestock', {
            ...from
        }, false, true, cb);
    }

    //育肥猪估重
    public getWeightRatio(cb: Function, from: any) {
        this.BasicGet('/animal/web/bigScreen/getWeightRatio', {
            ...from
        }, false, true, cb);
    }




    public getWeightRatio2(cb: Function, from: any) {
        this.BasicGet('/animal/web/bigScreenGovernment/getWeightRatio', {
            ...from
        }, false, true, cb);
    }


    //出栏情况
    public getmarketing2(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForCompany/government', {
            // animalTypeId: form.animalTypeId,
            // today: form.day,
            // currentWeek:form.week,
            // currentMonth:form.month,
            // pName:form.pName
            ...form
        }, false, true, cb);
    }


    public getslaughterCount(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForCompany/slaughterCount', {
            // animalTypeId: form.animalTypeId,
            // today: form.day,
            // currentWeek:form.week,
            // currentMonth:form.month,
            // pName:form.pName
            ...form
        }, false, true, cb);
    }

    public getabnormalTempCountForZf(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForCompany/abnormalTempCountForZf', {
            ...form
        }, false, true, cb);
    }



    public smartInventoryNew(cb: Function, from: any) {
        this.BasicGet('/animal/web/bigScreenForCountry/smartInventoryNew', {
            ...from
        }, false, true, cb);
    }

    // //获取当前登陆者的组织机构
    // public getForGroupCode(cb: Function) {
    //     this.BasicGet('/animal/web/provinceCityDistrict/getForGroupCode', {
    //         pid: -1
    //     }, false, true, cb);
    // }

    // //出栏情况
    // public getsevenmarketing(cb: Function,i: any) {
    //     this.BasicGet('/animal/web/bigScreenForCountry/sevenmarketing', {
    //         groupCode:localStorage.getItem('groupCode'),
    //         animalTypeId:i
    //     }, false, true, cb);
    // }


    // //存栏情况
    // public getlivestock(cb: Function,i: any) {
    //     this.BasicGet('/animal/web/bigScreenForCountry/livestock', {
    //         groupCode:localStorage.getItem('groupCode'),
    //         animalTypeId:1
    //     }, false, true, cb);
    // }


    // //统计区域下养殖场所类型数量和每日屠宰数
    // public getcountForPlaceAndKill(cb: Function) {
    //     this.BasicGet('/animal/web/bigScreenForCountry/countForPlaceAndKill', {
    //         groupCode:localStorage.getItem('groupCode'),
    //     }, false, true, cb);
    // }

    // //调出情况
    // public getmobilizeByDayForOut(cb: Function,i: any) {
    //     this.BasicGet('/animal/web/bigScreenForCountry/mobilizeByDayForOut', {
    //         groupCode:localStorage.getItem('groupCode'),
    //         animalTypeId:i
    //     }, false, true, cb);
    // }

    // //调入情况
    // public getmobilizeByDayForIn(cb: Function,i: any) {
    //     this.BasicGet('/animal/web/bigScreenForCountry/mobilizeByDayForIn', {
    //         groupCode:localStorage.getItem('groupCode'),
    //         animalTypeId:i
    //     }, false, true, cb);
    // }

    public getSlaughterStatic(farmId: string, cb: Function) {
        this.BasicGet('/animal/web/bigScreen/slaughterStatistics', {
            groupCode: this.getLocalStorage('groupCode'),
            farmId,
            // startTime: '2020-07-21',
            // endTime: '2020-07-23'
        }, false, true, cb);
    }

    public getSmartInventory(farmId: string, cb: Function) {
        this.BasicGet('/animal/web/bigScreen/smartInventory', {
            groupCode: this.getLocalStorage('groupCode'),
            farmId
        }, false, true, cb);
    }

    public getTypes(farmId: string, cb: Function) {
        this.BasicGet('/animal/web/bigScreen/getTypes', {
            groupCode: this.getLocalStorage('groupCode'),
            farmId
        }, false, true, cb);
    }

    public getInventoryStatistics(groupType: string, typeId: string, cb: Function) {
        this.BasicGet('/animal/web/bigScreen/inventoryStatistics', {
            groupCode: this.getLocalStorage('groupCode'),
            groupType,
            typeId,
        }, false, true, cb);
    }

    public getPigPrice(simpleAddressId: string, cb: Function) {
        this.BasicGet('/animal/web/bigScreen/getPigPrices', {
            groupCode: this.getLocalStorage('groupCode'),
            simpleAddressId,
            startTime: Util.getDay(-7),
            endTime: Util.getDay(0),
        }, false, true, cb);
    }


    public getPigPrice1(farmId: string, simpleAddressId: string, cb: Function) {
        this.BasicGet('/animal/web/bigScreen/pigPriceToDay', {
            groupCode: this.getLocalStorage('groupCode'),
            simpleAddressId,
            farmId,
        }, false, true, cb);
    }

    public getHouse(farmId: string, cb: Function) {
        this.BasicGet('/animal/web/animalFarmPlace/getThisAnimalFarmPlaceList', {
            farmId
        }, false, true, cb);
    }
    //新视频监控
    public chooseList(form: any, cb: Function) {
        this.BasicGet('/animal/web/bigScreenForCompanyNew/monitorList', {
            groupCode: form.id,
            farmId: form.farmId,
            animalTypeId: form.currentType
            // farmId: '494251603851739136',
        }, false, true, cb);
    }

    public chooseListTwo(form: any, page: any, cb: Function, errcb: Function) {
        this.BasicGet('/animal/web/newInsuranceLargeScreen/videoListV333', {
            groupCode: form.id,
            farmId: form.farmId,
            animalTypeId: form.currentType,
            streamType: 1,
            deviceStatus:form.deviceStatus,
            ...page
            // farmId: '494251603851739136',
        }, false, true, cb), errcb;
    }

    //新视频监控2
    public chooseList2(form: any, page: any, cb: Function, errcb: Function) {
        this.BasicGet('/animal/web/newEnterpriseLargeScreen/videoListV333', {
            groupCode: form.id,
            farmId: form.farmId,
            streamType: 1,
            animalTypeId: form.currentType,
            ...page
            // farmId: '494251603851739136',
        }, false, true, cb, errcb);
    }

    //新视频监控3
    public chooseList3(form: any, page: any, cb: Function, errcb: Function) {
        this.BasicGet('/animal/web/bigScreenForBankNew/videoListV333', {
            groupCode: form.id,
            farmId: form.farmId,
            animalTypeId: form.currentType,
            bankId: form.bankId,
            streamType: 1,
            ...page
            // farmId: '494251603851739136',
        }, false, true, cb, errcb);
    }

    //政府大屏视频列表
    public chooseList4(form: any, page: any, cb: Function, errcb: Function) {
        this.BasicGet('/animal/web/bigScreenGovernmentNew2/videoListV333', {
            groupCode: form,
            animalTypeId: 2,
            ...page
            // farmId: '494251603851739136',
        }, false, true, cb, errcb);
    }

    //骆驼视频列表
    public chooseList5(form: any, page: any, cb: Function, errcb: Function) {
        this.BasicGet('/animal/web/bigScreenGovernmentNew2/videoList', {
            groupCode: form,
            animalTypeId: 4,
            ...page
            // farmId: '494251603851739136',
        }, false, true, cb, errcb);
    }

    //获取单个视频监控地址
    public OneceVideoUrl(deviceCode: any, cb: Function) {
        this.BasicGet('/animal/web/newEnterpriseLargeScreen/videoUrl', {
            deviceCode
        }, false, true, cb);
    }

    public getfile(markId: string, cb: Function) {
        this.BasicGet('/animal/web/animalMark/getFileDetail', {
            markId
        }, false, true, cb);
    }
    //分娩记录
    public getRecord(id: string, tablePage: HandleTable, cb: Function) {
        this.BasicGet('/animal/web/childBirth/getRecordByMarkId', {
            id,
            ...tablePage,
        }, false, true, cb);
    }
    //孕检记录
    public getpregnancy(id: string, tablePage: HandleTable, cb: Function) {
        this.BasicGet('/animal/web/pregnancyRecord/getRecordByMarkId', {
            id,
            ...tablePage,
        }, false, true, cb);
    }

    //体温异常
    public getsingleTemp(id: string, tablePage: HandleTable, cb: Function) {
        this.BasicGet('/animal/web/singleTemp/getRecordByMarkId', {
            id,
            ...tablePage,
        }, false, true, cb);
    }

    //疫苗记录列表
    public getymlist(labelNumber: string, tablePage: HandleTable, cb: Function) {
        this.BasicGet('/animal/web/immuneRecord/list', {
            labelNumber,
            ...tablePage,
        }, false, true, cb);
    }
    //防治记录列表
    public getfzlist(labelNumber: string, tablePage: HandleTable, cb: Function) {
        this.BasicGet('/animal/web/controlRecord/list', {
            labelNumber,
            ...tablePage,
        }, false, true, cb);
    }



    //获取动物区块链信息
    public getBlockInfo(markId: string, cb: Function) {
        this.BasicGet('/animal/web/blockChain/getBlockInfo', {
            markId,
        }, false, true, cb);
    }



    public getBlockDatas(markId: string, tablePage: HandleTable, cb: Function) {
        this.BasicGet('/animal/web/blockChain/getBlockDatas', {
            markId,
            ...tablePage,
        }, false, true, cb);
    }


    public getBlockTempDatas(markId: string, cb: Function) {
        this.BasicGet('/animal/web/blockChain/getBlockTempDatas', {
            markId,
        }, false, true, cb);
    }

    // =============================================================================================
    public getGroupCodes(groupCode: string, cb: Function) {
        this.BasicGet('/animal/web/bigScreenInsurance/getGroupCodes', {
            groupCode,
        }, false, true, cb);
    }

    //死亡统计(保险版)
    public getdeathCount(form: any, cb: Function) {
        this.BasicGet('/animal/web/bigScreenForCompany/deathCount', {
            ...form,
        }, false, true, cb);
    }

    //体温异常预警(保险版)
    public getwarnTempCount(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForCompany/warnTempCount', {
            ...form,
        }, false, true, cb);
    }
    // public getwarnTempCount(cb: Function, form: any) {
    //     this.BasicGet('/animal/web/newBigScreenAndDeath/healthCountForInsurance', {
    //         ...form,
    //     }, false, true, cb);
    // }
    public getwarnTempCount2(cb: Function, form: any) {
        this.BasicGet('/animal/web/newBigScreenAndDeath/healthCountForBank', {
            ...form,
        }, false, true, cb);
    }
    public getwarnTempCount3(cb: Function, form: any) {
        this.BasicGet('/animal/web/newBigScreenAndDeath/healthCountForCompany', {
            ...form,
        }, false, true, cb);
    }


    //死亡种类占比(保险版)
    public getdeathTypes(form: any, cb: Function) {
        this.BasicGet('/animal/web/bigScreenForCompany/deathTypes', {
            ...form,
        }, false, true, cb);
    }

    //老监管
    public getinsuranceLivestock3(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenInsurance/superviseCount', {
            ...form,
        }, false, true, cb);
    }
    //监管
    public getinsuranceLivestock2(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForCompanyNew/getTakeStockData', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }

    //监管2
    public getTakeStockData(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForInsuranceNew/getTakeStockData', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }

    //监管3
    public getTakeStockData3(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForBankNew/getTakeStockData', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }
    //今日是否有出栏
    public todayOutColumn(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForCompanyNew/todayOutColumn', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }

    //今日是否有出栏
    public todayOutColumn2(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForInsuranceNew/todayOutColumn', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }
    //今日是否有出栏3
    public todayOutColumn3(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForBankNew/todayOutColumn', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }
    //获取存栏数量   
    public getAnimalCount(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForBankNew/getAnimalCount', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            bankId: form.bankId,
            farmId: form.farmId,
        }, false, true, cb);
    }
    //获取未盘点原因数量
    public noInventoryCause(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForCompanyNew/noInventoryCause', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }

    //获取未盘点原因数量
    public noInventoryCause2(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForInsuranceNew/noInventoryCause', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }

    //获取未盘点原因数量3
    public noInventoryCause3(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForBankNew/noInventoryCause', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }
    //弹窗1
    public noInventoryItem(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForCompanyNew/noInventoryItem', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }


    //弹窗2
    public noInventoryCauseItem(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForCompanyNew/noInventoryCauseItem', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }


    //弹窗3
    public noInventoryItem3(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForInsuranceNew/noInventoryItem', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }
    //弹窗4
    public noInventoryCauseItem4(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForInsuranceNew/noInventoryCauseItem', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }

    //弹窗5
    public noInventoryItem5(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForBankNew/noInventoryItem', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }
    //弹窗6
    public noInventoryCauseItem6(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForBankNew/noInventoryCauseItem', {
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }

    //盘点
    public getinsuranceLivestock(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenInsurance/dataInventory', {
            ...form,
        }, false, true, cb);
    }


    //剩余盘点
    public getresidualInventory(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenInsurance/dataInventory', {
            ...form,
        }, false, true, cb);
    }



    //监管2
    public getsuperviseCountBank(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForBank/superviseCountBank', {
            ...form,
        }, false, true, cb);
    }
    //盘点2
    public getdataInventoryForBank(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForBank/dataInventoryForBank', {
            ...form,
        }, false, true, cb);
    }


    //剩余盘点2
    public getdataInventoryForBank2(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForBank/dataInventoryForBank', {
            ...form,
        }, false, true, cb);
    }

    //耳标异常
    public getlabelExceptionCount(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenInsurance/labelExceptionCount', {
            ...form,
        }, false, true, cb);
    }


    //承保数量
    public getinsuredAmount(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenInsurance/insuredAmount', {
            ...form,
        }, false, true, cb);
    }
    //老非法离开
    public getLeaveRecords2(cb: Function, form: any) {
        console.log(form);
        this.BasicGet('/animal/web/bigScreenInsurance/getLeaveRecords', {
            // today: form.today,
            // currentWeek: form.currentWeek,
            // currentMonth: form.currentMonth,
            // animalTypeId: form.confType,
            // groupCode: form.groupCode,
            ...form
        }, false, true, cb);
    }

    //老非法离开
    public getLeaveRecords2(cb: Function, form: any) {
        console.log(form);
        this.BasicGet('/animal/web/bigScreenInsurance/getLeaveRecords', {
            // today: form.today,
            // currentWeek: form.currentWeek,
            // currentMonth: form.currentMonth,
            // animalTypeId: form.confType,
            // groupCode: form.groupCode,
            ...form
        }, false, true, cb);
    }

    //新非法离开
    public getLeaveRecords(cb: Function, form: any) {
        console.log(form);
        this.BasicGet('/animal/web/newInsuranceLargeScreen/leaveIllegallyCountToo', {
            today: form.today,
            currentWeek: form.currentWeek,
            currentMonth: form.currentMonth,
            animalTypeId: form.currentType,
            id: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }

    //耳标异常
    public earTagExcCount(cb: Function, form: any) {
        console.log(form);
        this.BasicGet('/animal/web/bigScreenForInsuranceNew/earTagExcCount', {
            dateType: form.dateType,
            animalTypeId: form.currentType,
            groupCode: form.id,
            farmId: form.farmId,
        }, false, true, cb);
    }

    //牲畜销售数量
    public livestockSoldCountToo(cb: Function, form: any) {
        this.BasicGet('/animal/web/newInsuranceLargeScreen/livestockSoldCountToo', {
            animalTypeId: form.currentType,
            id: form.id,
            farmId: form.farmId,
            // today: form.today,
            // currentWeek: form.currentWeek,
            // currentMonth: form.currentMonth
        }, false, true, cb);
    }
    //AI非法离开接口
    public leaveIllegallyForBankTof(cb: Function, form: any) {
        this.BasicGet('/animal/web/newBankLargeScreen/leaveIllegallyForBankTof', {
            animalTypeId: form.currentType,
            id: form.id,
            farmId: form.farmId,
            bankId: form.bankId, //3.1.5
            today: form.today,
            currentWeek: form.currentWeek,
            currentMonth: form.currentMonth
        }, false, true, cb);
    }

    //AI非法离开接口
    public livestockSoldForBankTof(cb: Function, form: any) {
        this.BasicGet('/animal/web/newBankLargeScreen/livestockSoldForBankTof', {
            animalTypeId: form.currentType,
            id: form.id,
            farmId: form.farmId,
            today: form.today,
            bankId: form.bankId, //3.1.5
            currentWeek: form.currentWeek,
            currentMonth: form.currentMonth
        }, false, true, cb);
    }

    public leaveIllegallyForEnterpriseTot(cb: Function, form: any) {
        this.BasicGet('/animal/web/newEnterpriseLargeScreen/leaveIllegallyForEnterpriseTot', {
            animalTypeId: form.currentType,
            id: form.id,
            farmId: form.farmId,
            today: form.today,
            currentWeek: form.currentWeek,
            currentMonth: form.currentMonth
        }, false, true, cb);
    }


    //各省抵押物数量（假数据）
    public getloanAnimal(cb: Function, form: any) {
        this.BasicGet('/animal/web/newBigScreenAndDeath/loanAnimal', {
            ...form,
        }, false, true, cb);
    }
    //newBigScreenAndDeath/leaveIllegallyForInsurance
    //newBigScreenAndDeath/leaveIllegallyForBank
    //newBigScreenAndDeath/leaveIllegallyForCompany
    //疾病预警
    public getdiseaseCount(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForCompany/diseaseCount', {
            ...form,
        }, false, true, cb);
    }

    //死亡情况
    public getdeathCountForBank(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForCompany/deathCountForBank', {
            ...form,
        }, false, true, cb);
    }

    //实时盘点
    public getbankStock(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenForBank/bankStock', {
            ...form,
        }, false, true, cb);
    }

    //放贷金额
    public getMortgageLoanAmount(cb: Function, form: any) {
        this.BasicGet('/animal/web/bigScreenBank/getMortgageLoanAmount', {
            ...form,
        }, false, true, cb);
    }


    //保險公司
    public getCurrentUserInsuranceCompany(cb: Function) {
        this.BasicGet('/animal/web/bigScreenInsurance/getCurrentUserInsuranceCompany', {
        }, false, true, cb);
    }

}



