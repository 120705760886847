






































































































































































































































































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import { Util } from "@/common/Views/comm/util";
import { BasicsClass } from "@/common/BasicsClass";
import axios from "axios";
import dpMap from "./modules/bigScreenMap.vue";
import barEchartDate from "./modules/barEchartDate.vue";
import pieEchart from "./modules/pieEchart.vue";
import { BigData } from "@/common/Views/bigData/bigData";
import Top from "./modules/top.vue";
import {
    getBigScreenGovernmentVideoGroupCode,
    getBigScreenGovernmentVideoList,
} from "@/api/index";
import {
    getBigScreenGovernmentVideoGroupCodeV333,
    getBigScreenGovernmentVideoListV333,
} from "@/api/GovernmentModule/index";
import { clear } from "xe-utils/methods";
@Component({
    components: {
        antiepidemic: () => import("./modules/GoverScreenAntiepidemic.vue"),
        dpMap,
        barEchartDate,
        pieEchart,
        Top,
    },
})
export default class governmentScreen extends Vue {
    private timeNow: any = ""; // 大屏右上角实时时间

    private time: number = null; // goTime 时间定时器，需要在销毁组件时清除

    private groupCodeList = []; // 左上角 地图 树结构

    private showCitySelection: boolean = false; // 树结构 隐藏/显示 控制

    private changeName: string = ""; // 当前 树结构 选择地区的 名称

    private authorityAdcode: number = null; //当前账户的地图权限

    private authorityName: string = null; // 当前账户权限对应的名称

    private changeCity: string = "-1";

    private TitleName: any = "中国";

    private curentAdcode: number = 0; // 当前区域的 adcode

    private cancelTokenNowCount = null;
    private pieEcharDataNowCount: any = {}; // 实时采集数量

    private cancelTokenYangzhi = null;
    private echartDataYangZhi: any = {}; // 养殖规模统计

    private cancelTokenChulan = null;
    private echartDataChuLan: any = {}; // 出栏数量

    private cancelTokenCount = null;
    private echartDataCount: any = {}; // 头数

    private cancelTokenTaskStock = null;
    private pieEcharDataTakeStock: any = {}; // 惠普金融活体贷款

    private cancelTokenBaoxian = null;
    private echartDataBaoxian: any = {}; // 保险投保

    private cancelTokenHealth = null;
    private echartHealthWarning: any = {}; // AI健康预警

    private cancelTokenDongjing = null;
    private echartDongJingData: any = {}; // 冻精数量

    private FarmId: any = "";

    private ShowVc: boolean = true;
    private async mounted() {
        try {
            // 初始化数据，获取视频分组数据
            await this.getBigScreenGovernmentVideoGroupCodeData();
            // 设置比例
            this.setScale();
            // 设置时间
            this.goTime();
            // 获取树结构
            await this.getGroupCode();
            // 获取图表信息
            this.refreshData();
        } catch (error) {
            console.error("Error during mounted initialization:", error);
        }

        let that = this;
        // 监听页面可见性变化
        document.addEventListener("visibilitychange", async function() {
            console.log(document.visibilityState);

            if (document.visibilityState === "hidden") {
                // 当页面隐藏时
                that.ShowVc = false;
            } else {
                // 当页面重新可见时
                that.ShowVc = true;
                that.setScale();
                that.goTime();
                try {
                    // 再次获取树结构
                    await that.getGroupCode();
                } catch (error) {
                    console.error(
                        "Error in visibilitychange group code fetch:",
                        error
                    );
                }
            }
        });
    }
    private generateRandomLevelId() {
        // 生成一个随机的唯一标识符，可以根据需要修改长度
        const uniqueId = Math.random()
            .toString(36)
            .substring(2, 10);

        return uniqueId;
    }

    private generateLevelId(data: any) {
        data.forEach((item: any) => {
            // 生成随机的 levelId
            item.levelId = this.generateRandomLevelId();

            // 如果当前项有子项，则递归调用此函数
            if (item.children && item.children.length > 0) {
                this.generateLevelId(item.children);
            }
        });
    }
    private needScale: boolean = false;

    setScale() {
        let clientWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (clientWidth === 1280) {
            // console.log('我这边是1920的150%缩放比例，因此需要设置scale');
            this.needScale = true;
        }
    }

    private beforeDestroy(): void {
        // 清除定时器
        clearInterval(this.time);
        clearInterval(this.timer);
    }

    private goTime() {
        this.time = window.setInterval(() => {
            let date = new Date();
            this.timeNow = Util.dateTimeUtil(date);
        }, 1000);
    }

    // 左上角返回
    private goback(): void {
        // new BasicsClass().BasicGet("/animal/web/menu/currentMenus",{},false,true,(res: any) => {
        // 	let url = res.data[0].children[0].url;
        // 	const path = this.$route.path === url ? '/homeep' : url;
        // 	// console.log('----router: ', this.$route.path, url, path)
        // 	this.$router.push(path);
        // });
        this.$router.push("/Welcome");
    }

    //给每个有详情的组件使用的groupcode id
    private sendGroupCodeId: string = "";
    private sendGroupName: string = "";

    //处理直辖市id的函数
    private changeGroupCodeFun(item: any) {
        console.log(item);
        this.sendGroupCodeId = "";
        this.sendGroupName = "";
        this.sendGroupName = item.name;
        if (item.id == "1101") {
            this.sendGroupCodeId = "11";
        } else if (item.id == "1201") {
            this.sendGroupCodeId = "12";
        } else if (item.id == "3101") {
            this.sendGroupCodeId = "31";
        } else if (item.id == "5001" || item.id == "5002") {
            this.sendGroupCodeId = "50";
        } else {
            this.sendGroupCodeId = item.id;
        }
    }

    // 处理 树结构展开 状态
    private handleCity(item: any): void {
        console.log("handleCity Data", item);
        this.showCitySelection = false;
        this.changeName = item.name;
        this.FarmId = item.farmId;
        this.changeGroupCodeFun(item);
        // 根据点击地区节点的ID 去匹配高德地区对应的ID，展开地图
        let id = item.gdGroupCode;
        return;
        if (item.id && item.id.toString().length === 6) {
            // console.log('表明我点击的是最后一层级，六位地区码');
            // 这个code地图是获取不到下一级区域的，所以无法获取地图节点对象，需要我们自己设置名称和点位信息
            this.$refs.dpMap.setLastLevelObj(
                item.name,
                item.longitude,
                item.latitude
            );
        }
        for (let i = Number(id).toString().length; i < 6; i += 1) {
            id += "0";
        }
        if (id === "000000") id = 100000;
        // console.log('补足id为六位，末尾加0：', id);
        // 点击了树结构，需要同步地图变化

        // this.$refs.dpMap.switch2AreaNode(Number(id), null, id);

        // console.log(this, Boolean(this.$refs.dpMap));
        let that = this;

        self();
        function self() {
            // console.log(Boolean(that), 666);
            if (that && that.$refs && that.$refs.dpMap) {
                // console.log('我成功的调用了地图组件', id);
                that.$refs.dpMap.switch2AreaNode(Number(id), null, id);
            } else {
                setTimeout(() => {
                    // console.log('地图还没有加载成功，所以我要一直调用');
                    self();
                }, 100);
            }
        }
    }

    // 子组件更新地区adcode
    private setCurentAdcode(adcode: number) {
        console.log(
            "地图组件更新了当前区域，所以我需要设置当前区域的数据",
            adcode
        );
        this.curentAdcode = adcode;
        // console.log('-------refreshdata', this.curentAdcode)
        this.refreshData();
    }

    // 子组件更新地区名称
    private setCurentAreaName(name: string): void {
        this.changeName = name;
    }

    // 地区改变，需要同时更新图表数据
    private refreshData(): void {
        console.log("地区改变，需要同时更新图表数据");
        this.setPieData(); // 更新 实时采集数量
        this.setYangZhiData(); // 更新 养殖规模统计
        this.setChuLanData(); // 更新 出栏数量
        this.setCountData(); // 更新 头数

        this.setJinRongPieData(); // 更新 普惠金融活体贷款
        this.setBaoXianData(); // 更新 保险投保
        this.setHealthWarningData(); // 更新 AI健康预警
        this.setDongjingData(); // 更新冻精数量

        this.chooseList();
    }

    // 树结构 请求接口
    private async getGroupCode() {
        await axios({
            method: "get",
            url: "/animal/web/bigScreenGovernmentNew/governmentGroupCode",
        }).then((res: any) => {
            // console.log('新版的后台树列表接口', res)
            const data = res.data.data;
            this.groupCodeList = data;
            this.changeName = data[0].name;
            this.authorityAdcode = data[0].id;
            this.authorityName = data[0].name;
            this.FarmId = data[0].farmId;
            // this.curentAdcode = data[0].id;
            let that = this;
            return;
            setTimeout(() => {
                // console.log('-----初始化地图数据');
                that.handleCity({
                    gdGroupCode: data[0].id,
                    name: data[0].name,
                    longitude: data[0].longitude,
                    latitude: data[0].latitude,
                    id: data[0].id,
                });
            }, 3000);
        });
        // return new Promise((resolve, reject) => {
        // 	new BasicsClass().BasicGet("/animal/web/bigScreenGovernmentNew/governmentGroupCode",{},false,true,
        // 		(res: any) => {
        // 			// console.log('新版的后台树列表接口', res)
        // 			this.groupCodeList = res.data;
        // 			this.changeName = res.data[0].name;
        // 			resolve(" ");
        // 		}
        // 	);
        // });
    }

    // 设置 养殖规模统计 数据
    async setYangZhiData(): Promise<void> {
        let _that = this;
        this.cacelFunc("cancelTokenYangzhi");

        // 写成方法调用是预留 本周/本月 操作，以免需求改变要重新改造方法，更麻烦
        let apiTestt = async function(param) {
            // console.log('/bigScreenGovernmentNew2/scaleStatistical 请求开始');
            await axios({
                method: "get",
                url: "/animal/web/bigScreenGovernmentNew2/scaleStatistical",
                params: {
                    groupCode: _that.curentAdcode,
                },
                cancelToken: new axios.CancelToken((c) => {
                    // console.log('-----------ccc', c)
                    _that.cancelTokenYangzhi = c;
                }),
            })
                .then((res: any) => {
                    _that.cancelTokenYangzhi = null;
                    // console.log('-------- 养殖规模统计 返回数据: ', res);
                    let data = JSON.parse(JSON.stringify(res.data.data));

                    if (_that.curentAdcode === 0 || true) {
                        // console.log('----全国的数据，需要过滤，不然饼图放不下');
                        data = data.filter((item) => {
                            // 把有数据的返回
                            let count = 0;
                            count =
                                item.elevenToThirty +
                                item.fiftyOneToOneHundred +
                                item.moreThanOneThousand +
                                item.oneHundredAndOneToOneThousand +
                                item.tenNum +
                                item.thirtyOneToFifty;
                            // console.log(Boolean(count), count);
                            return Boolean(count);
                        });

                        if (data.length === 0) {
                            // 如果一个数据都没有，取前十个，不然图例太多了
                            data = res.data.data.slice(0);
                        }
                    }
                    // console.log('过滤后的数据', data)

                    data.forEach((item) => {
                        let obj = {
                            tenNum: "10头",
                            elevenToThirty: "10-30头",
                            thirtyOneToFifty: "31-50头",
                            fiftyOneToOneHundred: "50-100头",
                            oneHundredAndOneToOneThousand: "100-1000头",
                            moreThanOneThousand: "1000头以上",
                        };
                        let children = {};

                        // 过滤为0的字段
                        Object.keys(obj).forEach((sub) => {
                            if (item[sub]) children[obj[sub]] = item[sub];
                        });

                        param.push({
                            value:
                                item.elevenToThirty +
                                item.fiftyOneToOneHundred +
                                item.moreThanOneThousand +
                                item.oneHundredAndOneToOneThousand +
                                item.tenNum +
                                item.thirtyOneToFifty,
                            // name: item.name.length > 5 ? item.name.slice(0, 4) + '...' : item.name,
                            name: item.name,
                            children,
                        });
                    });
                })
                .catch((err) => {
                    // console.log('---------- 养殖规模统计 请求出错', err)
                });
        };

        let pieData = [];
        await apiTestt(pieData);
        // console.log('养殖规模数据 Data: ', pieData)

        // 养殖规模 本来是柱状体，后来改成了饼图
        let echartDataYangZhi = {
            // xLength: this.needScale ? 36 : 38, 					// 图例超过多少个滚动效果
            changeDate: false, // 不分 周/月
            endValue: 3, // 最多显示4个
            delayTime: 1600, // 延迟加载
            yName: "户",
            autoTooltip: false, // 不自动展示
            showInnerPercent: true, // 饼图显示inner百分比
            highLightPercent: true, // 高亮显示的时候展示百分比数字
            pieMode: "mode2",
            pieData: pieData,
        };

        this.echartDataYangZhi = echartDataYangZhi;

        // 饼图数据结构
        // let pieData = [
        // 	{ value: 3265, pp: ' 占比：14%', name: '扎鲁特旗', children: { '10头': 4200, '10-30头': 3000, '31-50头': 6000, '50-100头': 700, '100-1000头': 3222, '1000头以上': 632, } },
        // ]

        // 柱状图数据结构
        // echartDataObj: {
        // 	'month': [
        // 		['category', '10头', '10-30头', '31-50头', '50-100头', '100-1000头', '1000头以上'],
        // 		['扎鲁特旗', 4200,3000,6000,700,3222,632,3],
        // 	]
        // },
    }

    // 设置 出栏数量 数据
    async setChuLanData(): Promise<void> {
        this.cacelFunc("cancelTokenChulan");

        let _that = this;
        let apiTest = async function(type, param) {
            await axios({
                method: "get",
                url: "/animal/web/bigScreenGovernmentNew2/outColumnList",
                params: {
                    groupCode: _that.curentAdcode,
                    type: type,
                },
                cancelToken: new axios.CancelToken((c) => {
                    // console.log('-----------ccc', c)
                    _that.cancelTokenChulan = c;
                }),
            })
                .then((res: any) => {
                    _that.cancelTokenChulan = null;
                    res.data.data.typeList.forEach((item) => {
                        param[0].push(item.typeName);
                    });
                    if (!res.data.data.typeList.length) {
                        param[0].push("基础母牛", "架子牛", "肉牛", "犊牛");
                    }

                    // console.log('-------- 出栏数量 返回数据: ', res);
                    res.data.data.outColumnNewOutputList.forEach((item) => {
                        // console.log('--此地区数量', item);
                        let arr = [item.name];

                        // item.outColumnDtoList.forEach(sub => {
                        // 	arr.push(sub.num);
                        // })

                        for (let i = 1; i < param[0].length; i += 1) {
                            let flag = true;

                            item.outColumnDtoList.forEach((sub) => {
                                if (param[0][i] === sub.typeName) {
                                    flag = false;
                                    arr.push(sub.num);
                                }
                            });

                            if (flag) {
                                arr.push(0);
                            }
                        }

                        if (arr.length === 1) {
                            [0, 0, 0, 0].forEach((sub) => {
                                arr.push(sub);
                            });
                        }
                        param.push(arr);
                    });
                    // console.log('转换后的param：', param)
                })
                .catch((err) => {
                    // console.log(err)
                });
        };

        // [['category', '基础母牛', '架子牛', '肉牛', '犊牛']]
        let month = [["category"]];
        let week = [["category"]];

        await apiTest(3, month);
        await apiTest(2, week);

        let echartDataChuLan = {
            barColor: [
                "#04D1BD",
                "#0352DA",
                "#0EA9E8",
                "#523BFF",
                "#A772FA",
                "#CE9E4D",
                "#024FEB",
                "#0EA9E8",
            ],
            delayTime: 1000,
            changeDate: true,
            barWidth: 10,
            echartDataObj: {
                month,
                week,
            },
        };

        // console.log('echartDataChuLan 过滤后的图表数据', echartDataChuLan)

        this.echartDataChuLan = echartDataChuLan;

        // 数据结构
        // 'month': [
        // 	['category', '基础母牛', '架子牛', '肉牛', '犊牛'],
        // 	['扎鲁特旗', 421,130,633,730],
        // ],
        // 'week': [
        // 	['category', '基础母牛', '架子牛', '肉牛', '犊牛'],
        // 	['扎鲁特旗', 21,13,63,3],
        // ]
    }

    // 设置 头数 数据
    async setCountData(): Promise<void> {
        let _that = this;
        this.cacelFunc("cancelTokenCount");
        let apiTest = async function(type, param) {
            await axios({
                method: "get",
                url:
                    "/animal/web/bigScreenGovernmentNew2/numAndbreedStatistical",
                params: {
                    groupCode: _that.curentAdcode,
                    type: type,
                },
                cancelToken: new axios.CancelToken((c) => {
                    // console.log('-----------ccc', c)
                    _that.cancelTokenCount = c;
                }),
            })
                .then((res: any) => {
                    _that.cancelTokenCount = null;
                    // console.log('-------- 头数 返回数据: ', res);
                    res.data.data.forEach((item) =>
                        param.push([
                            item.name,
                            // item.threeHundred,
                            item.twoHundredToThreeHundred,
                            item.threeHundredToFourHundred,
                            item.fourKgUpBaseCowHundred,
                            item.fourKgUpFatCowHundred,
                        ])
                    );
                    // [
                    // 	item.name,
                    // 	// item.threeHundred,
                    // 	item.threeHundredToFourHundred,
                    // 	item.fourHundredToFiveHundred,
                    // 	item.fiveHundredToSixHundred, item.sixHundredToSevenHundred,
                    // 	item.sevenHundredToEightHundred, item.moreThanEightHundred
                    // ]
                })
                .catch((err) => {
                    // console.log(err)
                });
        };

        // let month = [['category', '300-400斤', '400-500斤', '500-600斤', '600-700斤', '700-800斤', '800斤以上']];
        let month = [
            [
                "category",
                "200-300公斤犊牛",
                "300-400公斤架子牛",
                "400公斤以上基础母牛",
                "400公斤以上育肥牛",
            ],
        ];
        let week = [
            [
                "category",
                "200-300公斤犊牛",
                "300-400公斤架子牛",
                "400公斤以上基础母牛",
                "400公斤以上育肥牛",
            ],
        ];

        apiTest(3, month);
        // await apiTest(2, week);

        let echartDataCount = {
            changeDate: false, // 不分 周/月
            gridTop: 10,
            endValue: 3, // 最多显示4个
            delayTime: 1400,
            echartDataObj: {
                month,
                week,
            },
        };

        this.echartDataCount = echartDataCount;

        // 数据结构
        // 'month': [
        // 	['category', '300斤以下', '300-500斤', '500-600斤', '600-700斤', '700-800斤', '800斤以上'],
        // 	['扎鲁特旗', 1421,1230,3633,4730,434,122],
        // ],
        // 'week': [
        // 	['category', '300斤以下', '300-500斤', '500-600斤', '600-700斤', '700-800斤', '800斤以上'],
        // 	['扎鲁特旗', 21,13,63,3,12,35],
        // ]
    }

    // 设置 普惠金融活体贷款 数据
    async setJinRongPieData(): Promise<void> {
        let _that = this;
        this.cacelFunc("cancelTokenTaskStock");
        // 写成方法调用是预留 本周/本月 操作，以免需求改变要重新改造方法，更麻烦
        let apiTestt = async function(param) {
            await axios({
                method: "get",
                url: "/animal/web/bigScreenGovernmentNew2/loanData",
                params: {
                    groupCode: _that.curentAdcode,
                },
                cancelToken: new axios.CancelToken((c) => {
                    // console.log('-----------ccc', c)
                    _that.cancelTokenTaskStock = c;
                }),
            })
                .then((res: any) => {
                    _that.cancelTokenTaskStock = null;
                    // console.log('-------- 普惠金融活体贷款 返回数据: ', res);

                    let data = JSON.parse(JSON.stringify(res.data.data));

                    if (_that.curentAdcode === 0 || true) {
                        // console.log('----全国的数据，需要过滤，不然饼图放不下');
                        data = data.filter((item) => {
                            // 把有数据的返回
                            let count = 0;
                            count =
                                item.fifty +
                                item.fiftyToOneHundred +
                                item.oneHundredToThreeHundred +
                                item.threeHundredToFiveHundred +
                                item.moreThanFiveHundred;
                            // console.log(Boolean(count), count);
                            return Boolean(count);
                        });

                        if (data.length === 0) {
                            // 如果一个数据都没有，取前十个，不然图例太多了
                            data = res.data.data.slice(0);
                        }
                    }
                    // console.log('过滤后的数据', data)

                    data.forEach((item) => {
                        let obj = {
                            fifty: "50万以下",
                            fiftyToOneHundred: "50-100万",
                            oneHundredToThreeHundred: "100-300万",
                            threeHundredToFiveHundred: "300-500万",
                            moreThanFiveHundred: "500万以上",
                        };
                        let children = {};

                        // 过滤为0的字段
                        Object.keys(obj).forEach((sub) => {
                            if (item[sub]) children[obj[sub]] = item[sub];
                        });

                        param.push({
                            name: item.name,
                            // name: item.name.length > 5 ? item.name.slice(0, 4) + '...' : item.name,
                            value:
                                item.fifty +
                                item.fiftyToOneHundred +
                                item.oneHundredToThreeHundred +
                                item.threeHundredToFiveHundred +
                                item.moreThanFiveHundred,
                            children,
                        });
                    });
                })
                .catch((err) => {
                    // console.log('---------- 普惠金融活体贷款 请求出错', err)
                });
        };

        let pieData = [];
        await apiTestt(pieData);
        // console.log('------普惠金融活体贷款 配置后的数据', pieData);

        let pieEcharDataTakeStock = {
            delayTime: 1800,
            pieName: "",
            routerPath: "/loanapproval",
            highLightPercent: true,
            autoTooltip: false,
            showInnerPercent: true,
            pieMode: "mode2",
            // xLength: this.needScale ? 12 : 14, 					// 图例超过多少个滚动效果
            pieData,
        };

        this.pieEcharDataTakeStock = pieEcharDataTakeStock;

        // 数据结构
        // pieData: [ { value: 3265, name: '扎鲁特旗', children: { '50万以下': '13户', '100-300万': '7户', '500万以下': '3户', } } ],
    }

    // 设置 保险投保 数据
    async setBaoXianData(): void {
        // 定义函数
        let _that = this;
        this.cacelFunc("cancelTokenBaoxian");
        let apiTest = async function(param) {
            await axios({
                method: "get",
                url: "/animal/web/bigScreenGovernmentNew2/insuranceData",
                params: {
                    groupCode: _that.curentAdcode,
                },
                cancelToken: new axios.CancelToken((c) => {
                    // console.log('-----------ccc', c)
                    _that.cancelTokenBaoxian = c;
                }),
            })
                .then((res: any) => {
                    _that.cancelTokenBaoxian = null;
                    // console.log('-------- 保险投保 返回数据: ', res);
                    res.data.data.forEach((item) =>
                        param.push([
                            item.name,
                            item.policyNum,
                            item.businessNum,
                        ])
                    );
                })
                .catch((err) => {
                    // console.log(err)
                });
        };

        let month = [["category", "政策性保险", "商业性保险"]];
        let week = [["category", "政策性保险", "商业性保险"]];

        await apiTest(month);

        let echartDataBaoxian = {
            changeDate: false, // 没有 月/周
            legendTop: 1,
            legendRight: 100,
            routerPath: "/insureRecord",
            barColor: ["#523BFF", "#34DEF7"],
            endValue: 3, // 最大显示4个
            delayTime: 2200,
            echartDataObj: {
                month,
                week,
            },
        };

        this.echartDataBaoxian = echartDataBaoxian;

        // 数据结构
        // 'month': [
        // 	['category', '政策性保险', '商业性保险'],
        // 	['扎鲁特旗', 1421,130],
        // ],
    }

    // 设置 AI健康预警 数据
    async setHealthWarningData(): Promise<void> {
        let _that = this;
        this.cacelFunc("cancelTokenHealth");
        let apiTest = async function(type, param) {
            await axios({
                method: "get",
                url: "/animal/web/bigScreenGovernmentNew/healthAlarmList",
                params: {
                    groupCode: _that.curentAdcode,
                    type: type,
                },
                cancelToken: new axios.CancelToken((c) => {
                    // console.log('-----------ccc', c)
                    _that.cancelTokenHealth = c;
                }),
            })
                .then((res: any) => {
                    _that.cancelTokenHealth = null;
                    // console.log('--------AI健康预警返回数据: ', res);
                    res.data.data.forEach((item) =>
                        param.push([item.name, item.cowCount])
                    );
                })
                .catch((err) => {
                    // console.log(err)
                });
        };

        let week = [["category", "AI预警"]];
        let month = [["category", "AI预警"]];

        await apiTest(2, week);
        await apiTest(3, month);

        let echartHealthWarning = {
            changeDate: true,
            routerPath: "/healthWarning",
            showLegend: false, // 不显示legend图例
            barColor: ["#FF9000"],
            endValue: 7, // 最大显示8个
            delayTime: 2400,
            barMode: "mode2",
            echartDataObj: {
                month: month,
                week: week,
            },
        };

        this.echartHealthWarning = echartHealthWarning;

        // 数据结构
        // let echartDataObj = {
        // 	'month': [
        // 		['category', 'AI预警'],
        // 		['扎鲁特旗', 1421],
        // 	],
        // 	'week': [
        // 		['category', 'AI预警'],
        // 		['扎鲁特旗', 421],
        // 	],
        // }
    }

    // 设置 冻精数量 数据
    async setDongjingData(): Promise<void> {
        let _that = this;
        this.cacelFunc("cancelTokenDongjing");
        let apiTest = async function(param) {
            await axios({
                method: "get",
                url:
                    "/animal/web/bigScreenGovernmentNew2/frozenSemenStatistical",
                params: { groupCode: _that.curentAdcode },
                cancelToken: new axios.CancelToken((c) => {
                    // console.log('-----------ccc', c)
                    _that.cancelTokenDongjing = c;
                }),
            })
                .then((res: any) => {
                    _that.cancelTokenDongjing = null;
                    // console.log('-------- 冻精数量 返回数据: ', res);
                    res.data.data.forEach((item) =>
                        param.push([item.name, item.issueNum, item.useNum])
                    );
                })
                .catch((err) => {
                    // console.log(err)
                });
        };

        let month = [["category", "冻精发放数量", "冻精使用数量"]];
        let week = [["category", "冻精发放数量", "冻精使用数量"]];

        await apiTest(month);

        let echartDongJingData = {
            changeDate: false, // 没有 月/周
            barColor: ["#9060DC", "#523BFF"],
            endValue: 4,
            yName: "支",
            delayTime: 2800,
            echartDataObj: { month, week },
        };

        this.echartDongJingData = echartDongJingData;

        // 'month': [
        // 	['category', '冻精使用发放', '冻精使用数量'],
        // 	['扎鲁特旗', 421,330],
        // ],
    }

    private cacelFunc(funcName: string) {
        if (typeof this[funcName] === "function") {
            this[funcName]();
        }
    }

    // 设置 实时采集数据
    async setPieData(): Promise<void> {
        let _that = this;
        this.cacelFunc("cancelTokenNowCount");

        // 写成方法调用是预留 本周/本月 操作，以免需求改变要重新改造方法，更麻烦
        let apiTestt = async function(param) {
            await axios({
                method: "get",
                url: "/animal/web/bigScreenGovernmentNew2/getTakeStockData",
                params: {
                    groupCode: _that.curentAdcode,
                },
                cancelToken: new axios.CancelToken((c) => {
                    // console.log('-----------ccc', c)
                    _that.cancelTokenNowCount = c;
                }),
            })
                .then((res: any) => {
                    _that.cancelTokenNowCount = null;
                    // console.log('-------- 实时采集数据 返回数据: ', res);
                    let obj = res.data.data.haveInventoryTypeAndCount;
                    const keyArr = Object.keys(obj);

                    keyArr.forEach((item) => {
                        // if (item.includes('牛')) {
                        // }
                        param.push({ value: obj[item], name: item });
                    });

                    // 因为如果没有数据的话，返回的是个空对象，连分类都没有，需要自己设置初始分类
                    if (!param.length) {
                        let temp = [
                            { value: 0, name: "基础母牛" },
                            { value: 0, name: "架子牛" },
                            { value: 0, name: "肉牛" },
                            { value: 0, name: "犊牛" },
                        ];
                        temp.forEach((item) => param.push(item));
                    }
                })
                .catch((err) => {
                    // console.log('---------- 实时采集数据 请求出错', err)
                });
        };

        let pieData = [];
        await apiTestt(pieData);
        let pieEcharDataNowCount = {
            pieName: "实时采集数量",
            showAuto: true,
            delayTime: 200,
            routerPath: "/infoRegister",
            // xLength: this.needScale ? 12 : 14, 					// 图例超过多少个滚动效果
            pieData,
        };

        this.pieEcharDataNowCount = pieEcharDataNowCount;

        // let pieData = [
        // 	{ value: 3265, name: '基础母牛' },
        // ]
    }

    private videoList: any[] = [];
    private TopFlag: boolean = false;
    private page: any = {
        pageSize: 2,
        pageNo: 1,
        totalCount: 0,
    };
    prev() {
        this.page.pageNo--;
        if (this.page.pageNo >= 1) {
            this.prevList();
        } else {
            this.page.pageNo = 1;
        }
    }
    next() {
        if (this.page.pageSize * this.page.pageNo < this.page.totalCount) {
            this.page.pageNo++;
            this.nextList();
        }
    }
    private AddListNumber: number = 2;
    private prevList() {
        new BigData().chooseList4(
            this.curentAdcode,
            this.page,
            (data: any) => {
                let listArr: any[] = [];
                if (data.data.length != 0) {
                    for (
                        let i = 0;
                        i < data.data.length;
                        i += this.AddListNumber
                    ) {
                        listArr.push(
                            data.data.slice(i, i + this.AddListNumber)
                        );
                    }
                } else {
                    listArr = [];
                }
                //处理数组
                this.videoList = listArr;
                console.log(this.videoList);
                this.TopFlag = true;
            },
            (errData: any) => {
                this.videoList = [];
            }
        );
    }
    private nextList() {
        new BigData().chooseList4(
            this.curentAdcode,
            this.page,
            (data: any) => {
                let listArr: any[] = [];
                if (data.data.length != 0) {
                    for (
                        let i = 0;
                        i < data.data.length;
                        i += this.AddListNumber
                    ) {
                        listArr.push(
                            data.data.slice(i, i + this.AddListNumber)
                        );
                    }
                } else {
                    listArr = [];
                }
                //处理数组
                this.videoList = listArr;
                this.TopFlag = true;
            },
            (errData: any) => {
                this.videoList = [];
            }
        );
    }
    private chooseList() {
        this.TopFlag = false;
        let form: any = {
            groupCode: this.curentAdcode,
            animalTypeId: "",
            farmId: this.FarmId,
            deviceCode: "",
        };
        getBigScreenGovernmentVideoListV333(form, this.page, (data: any) => {
            this.page.totalCount = data.page.totalCount;
            let listArr: any[] = [];
            if (data.data.length != 0) {
                for (let i = 0; i < data.data.length; i += this.AddListNumber) {
                    listArr.push(data.data.slice(i, i + this.AddListNumber));
                }
            } else {
                listArr = [];
            }
            //处理数组
            this.videoList = listArr;
            console.log(this.videoList);

            this.TopFlag = true;
        });
    }

    private VideoGroupCodeData: any[] = []; //传递的左侧地址数据
    private VideoListData: any = {
        page: {
            totalCount: 0,
        },
    }; //传递的视频列表数据
    private PageObj: any = {
        pageNo: 1,
        pageSize: 4,
    };
    private ChildObjVal: any = {}; //子组件传递过来的值
    private VideolaunchData: any[] = []; //传递的左侧地址数据
    private getBigScreenGovernmentVideoGroupCodeData() {
        getBigScreenGovernmentVideoGroupCodeV333((data: any) => {
            this.VideoGroupCodeData = data.data;
            this.generateLevelId(this.VideoGroupCodeData);
            this.VideolaunchData = this.extractLevelIds();
        });
    }
    private extractLevelIds() {
        const levelIds: any = [];

        // 遍历第一层数据
        this.VideoGroupCodeData.forEach((item: any) => {
            // 添加当前项的levelId到数组中
            levelIds.push(item.levelId);
        });
        return levelIds;
    }
    private SetFatherVideoSendData(val: any) {
        //子子组件的emit事件  告诉此组件需要请求数据
        this.ChildObjVal = val;
        this.PageObj.pageNo = 1; //地址变化之后  将PageObj的 pageNo = 1
        this.getBigScreenGovernmentVideoListData(val, this.PageObj); //请求数据  并传递给子组件
    }

    private getBigScreenGovernmentVideoListData(form: any, page: any) {
        //请求视频列表的数据
        getBigScreenGovernmentVideoListV333(form, page, (data: any) => {
            console.log(data);

            this.VideoListData = data;
            this.PageObj = data.page;
        });
    }
    private Counttemp = 0;
    private timer: any = null;
    private handleDataFromChild(data: any) {
        // 接收到子组件传递的数据
        this.Counttemp = data.Counttemp;
        console.log(this.Counttemp, "this.Count");
        this.$refs.numberCase = document.getElementsByClassName(
            "fields_num"
        )[0];
        if (this.$refs.numberCase.textContent == this.Counttemp[0].markCount) {
            return;
        }
        let number = this.Counttemp[0].markCount;
        let duration = 5000; // 5秒时间
        let intervalTime = 20; // 每次递增间隔时间
        let steps = Math.ceil(duration / intervalTime); // 总步数
        let currentCount = 0;
        let increment = number / steps; // 计算每一步应该增加的基础值

        this.timer = setInterval(() => {
            // 在基础步长的基础上，加入随机性（这里使用 80% 到 120% 的范围）
            let randomFactor = 0.8 + Math.random() * 0.4;
            let stepIncrement = increment * randomFactor;

            currentCount += stepIncrement;

            if (currentCount < number) {
                this.$refs.numberCase.textContent = Math.floor(currentCount); // 显示的数字取整
            } else {
                this.$refs.numberCase.textContent = number; // 到达最终数字
                clearInterval(this.timer); // 停止计时器
            }
        }, intervalTime);
    }
    private getVideoList() {
        //子组件告诉此组件  依据当前组件的数据 获取视频列表的数据  点击更多的emit
        this.showCitySelection = false;
        this.PageObj.pageNo = 1; //点击更多 始终使用本页面的数据参数请求  保持 页码为1
        let VideoForm: any = {
            groupCode: this.curentAdcode,
            animalTypeId: "",
            farmId: this.FarmId,
            deviceCode: "",
        };
        this.handlerChildObj();
        this.getBigScreenGovernmentVideoListData(VideoForm, this.PageObj);
    }

    private getNextPage(val: any) {
        this.handlerChildObj();
        if (
            this.PageObj.pageSize * this.PageObj.pageNo <
            this.PageObj.totalCount
        ) {
            this.PageObj.pageNo++;
            if (this.ChildObjVal.groupCode) {
                this.ChildObjVal.farmId = null;
            }
            this.getBigScreenGovernmentVideoListData(
                this.ChildObjVal,
                this.PageObj
            );
        }
    }
    private getPrevPage() {
        this.handlerChildObj();
        this.PageObj.pageNo--;
        if (this.PageObj.pageNo >= 1) {
            if (this.ChildObjVal.groupCode) {
                this.ChildObjVal.farmId = null;
            }
            this.getBigScreenGovernmentVideoListData(
                this.ChildObjVal,
                this.PageObj
            );
        } else {
            this.PageObj.pageNo = 1;
        }
    }
    private ClearObj(val: any) {
        this.ChildObjVal = val;
    }
    @Watch("curentAdcode")
    private ChangeCode(newVal: any) {
        this.chooseList();
    }
    @Watch("FarmId")
    private ChangeFarmId(val: any) {
        this.chooseList();
    }

    private handlerChildObj() {
        this.ChildObjVal.groupCode != undefined &&
        this.ChildObjVal.groupCode != null
            ? (this.ChildObjVal.groupCode = this.ChildObjVal.groupCode)
            : (this.ChildObjVal.groupCode = this.curentAdcode);
        this.ChildObjVal.farmId
            ? (this.ChildObjVal.farmId = this.ChildObjVal.farmId)
            : (this.ChildObjVal.farmId = this.FarmId);
    }
}
